@font-face {
  font-family: "openSans-regular"; /*Can be any text*/
  src: local("openSans-Regular"), url("./fonts/OpenSans-Regular.ttf") format("woff");
}

.old-question-text {
  direction: rtl;
  text-align: right;
  width: 520px;
  padding-right: 30px;
  margin: 15px 0 -15px;
  font-style: italic;
  font-size: 18px;
}

.tags-title {
  text-align: center;
  direction: rtl;
  color: #373737;
  font-size: 18px;
  margin-top: 70px;
}
.tags-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 15px;
}
.tag-bubble {
  border-radius: 15px;
  border: solid 1px #000;
  min-width: 46px;
  height: 25px;
  padding: 2px 7px 0;
  cursor: pointer;
  font-size: 14px;
  gap: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: unset;
  text-align: unset;
  color: #000;
}

.tag-bubble:hover {
  background-color: black;
  color: white;
}

.logo-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
}

.did-you-know-container {
  display: flex;
}

.stick-to-bottom {
  padding-bottom: 50px;
}

.logo-container.with-ans {
  position: sticky;
  padding-top: 5px;
}
.explanation-text {
  direction: rtl;
  text-align: center;
  padding: 0 20px;
  font-size: 16px;

  max-width: 800px;
  margin: 0 auto;
}
.logo {
  width: 56%;
}

.links-container {
  display: flex;
  justify-content: space-evenly;
  width: 560px;
  max-width: 100%;
  margin: 0 auto;
}

.policy-link {
  cursor: pointer;
  text-align: center;
  width: unset;
}
.about-us {
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
}

.question-container {
  width: 100%;
  display: flex;
  margin-top: 130px;
  align-items: center;
  flex-direction: column;
}

.advertising-section {
  display: none;
}

.speak-icon {
  position: relative;
  bottom: -20px;
}

.speak-icon,
.active {
  width: 19px;
  height: 19px;
}

.policy-page {
  direction: rtl;
  overflow-x: hidden;
  padding: 5px 10px;
}

.about-us-section {
  direction: rtl;
  text-align: right;
  padding: 0 10px;
  margin-top: 50px;
}

.policy-page.with-ans {
  margin-top: 100vh;
  position: relative;
  top: -20px;
}

.ans-que-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 580px;
  /* margin: 90px auto 0; */
  margin: 20px auto 0;
  height: calc(100vh - 230px);
  justify-content: flex-end;
}

.logo-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  gap: 11px;
}

.logo-onebi {
  width: 126px;
  height: 22px;
}

.answer {
  font-size: 18px;
  padding-right: 30px;
}

.like-icon-answer {
  align-self: end;
  margin-left: 6px;
}

.single-ans-que-container {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -5px;
}

.like-text-answer {
  float: left;
  margin-right: 4px;
  color: gray;
  font-size: 13px;
  position: relative;
  top: 3px;
}

.answer-text {
  direction: rtl;
  text-align: right;
  width: 580px;
  margin-bottom: 30px;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
}

.search-logo {
  margin-left: 4px;
  height: 40px;
  width: 48px;
}

.advertising-section {
  width: 100%;
  height: 70px;
  margin-bottom: 10px;
}

.beta-version-text {
  color: deeppink;
  margin-top: 15px;
  font-size: 22px;
}

.questions-so-far-text {
  margin-top: 40px;
  color: black;
  font-size: 18px;
}

.gpt-3-span {
  cursor: pointer;
}

.gpt-desc-title {
  margin-top: 50px;
  text-align: center;
  font-size: 16px;
  color: grey;
}

.ad-for-desktop {
  height: calc(100vh + 127px);
  width: 150px;
  position: absolute;
  background-color: grey;
}

.ad-for-desktop-new {
  margin: 20px auto 0;
  display: flex;
  width: 728px;
  height: 90px;
  /* width: 70vw;
  max-width: 800px; */
}

.ad-for-desktop.right {
  right: 0;
}

.chat-box {
  bottom: 50px;
  right: 30px;
  position: absolute;
  height: 400px;
  width: 250px;
}

.ad-for-desktop.top {
  width: calc(100vw - 400px);
  height: 150px;
  position: static;
  margin: 0 auto;
}
.ad-for-mobile {
  display: none;
}

.to-examples {
  margin-top: 10px;
}

.logo-passover {
  width: 500px;
}

@media only screen and (max-width: 600px) {
  .links-container {
    flex-direction: column;
    width: unset !important;
  }
  .ad-for-desktop {
    display: none;
  }
  .ad-for-desktop-new {
    display: none;
  }
  .logo-passover {
    width: 400px;
  }
  .ad-for-mobile {
    position: static;
    margin: 0 auto;
    width: 90vw;
    display: block;
  }
  .ad-for-mobile.loader-ad {
    height: 140px;
    width: 340px;
  }

  textarea {
    width: 65%;
  }

  .question-container {
    margin-top: 30px;
  }
  .ans-que-container {
    margin-top: 40px;
    width: calc(100vw - 80px);
    margin: 15px auto 0;
  }

  .answer-text {
    max-height: calc(100vh - 300px);
    font-size: 16px;
    width: calc(100vw - 80px);
  }

  .advertising-section {
    display: block;
  }

  .tags-container {
    padding: 0 10px;
  }

  .questions-so-far-text {
    font-size: 16px;
  }
  .logo {
    width: 50%;
  }
  .old-question-text {
    width: unset;
  }

  .gpt-desc-title {
    font-size: 14px;
  }
  .stick-to-bottom {
    padding-bottom: 10px;
  }

  .logo-link-container {
    margin-bottom: 10px;
  }
  .tags-title {
    margin-top: 10%;
  }
}
