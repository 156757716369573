.loader-full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 9999;
  .loader-container img {
    height: 250px;
  }

  .loader-text {
    text-align: right;
    color: #303030;
    font-weight: 600;
    font-size: 18px;
  }

  .loader-container {
    display: flex;
    flex-direction: column;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loader-data-container {
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    background-color: #f2f2f4;
    display: flex;
  }
  .loader-text-container {
    width: 400px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    padding-right: 20px;
  }

  .loader {
    width: 100%;
    height: 4.8px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    z-index: 100;
  }
  .loader::after {
    content: "";
    width: 96px;
    height: 4.8px;
    background: #b7b7b7;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: hitZak 1s ease-in-out infinite alternate;
  }
  @keyframes hitZak {
    0% {
      left: 0;
      transform: translateX(-1%);
    }
    100% {
      left: 100%;
      transform: translateX(-99%);
    }
  }
}

@media only screen and (max-width: 600px) {
  .loader-full-screen {
    .loader-text {
      font-size: 16px;
    }
    .loader-text-container {
      width: 200px;
      height: 140px;
    }
    .loader-container img {
      height: 140px;
    }
  }
}
