p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
li,
strong,
button,
span,
div,
.answer-text {
  font-family: "openSans-regular";
}
.center-section {
  text-align: center;
  /* width: 100vw; */
}
html,
body,
#root,
.app {
  /* height: 100%; */
  width: 100%;
}
* {
  margin: 0;
}
.margin0 {
  margin: 0;
}

.dont-show {
  opacity: 0;
  z-index: 0;
  height: 0;
}

svg {
  cursor: pointer;
}

a {
  width: 100%;
  text-align: center;
}

.marginTop40 {
  margin-top: 40px !important;
}

.answer-text::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #fafafa;
}

.answer-text::-webkit-scrollbar {
  width: 6px;
  background-color: #fafafa;
}

.answer-text::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
}
