.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: space-around;
  .logos-container {
    margin-top: 23%;
    margin-bottom: 14%;
  }

  .login-btns-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 325px;
      height: 46px;
      border: 0;
      background-color: transparent;
      font-size: 14px;
      color: white;
    }

    .new-user-btn {
      background-image: url(../assets/black-new-user-btn.svg);
    }

    .login-user-btn {
      background-image: url(../assets/blue-login-user.svg);
      margin-top: 20px;
    }
  }

  .login-footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .gpt-title {
      font-size: 16px;
    }
  }
}
