.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-to-bottom {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  //   width: 100vw;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;

  .margin0 {
    margin: 0;
  }
}
