.about-us-section {
  h2 {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  h4 {
    margin-top: 15px;
    margin-bottom: 5px;
  }
}
