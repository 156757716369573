.ques-examples-page {
  display: flex;
  flex-direction: column;
  max-width: 90%;
  // gap: 20px;
  align-items: center;

  margin: 0 auto;

  .header {
    display: flex;

    .logo-container {
      width: 87px;
      height: 46px;
      padding-top: 20px;
    }
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #4a4a4a;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .search-examples-input {
    border-radius: 10px;
    border: solid 1px #9e9e9e;
    direction: rtl;
    width: 390px;
    height: 40px;
    color: black;
    font-size: 16px;
    padding-right: 20px;
    max-width: 90%;
    margin-bottom: 30px;
  }

  .search-examples-input::placeholder {
    color: #a6b1c2;
  }

  .single-ques-ans {
    max-width: 90%;
    width: 410px;
    // height: 137px;
    padding-bottom: 12px;
    border-bottom: solid 0.3px #646464;
    margin-bottom: 15px;

    .question {
      font-size: 16px;
      color: #4a4a4a;
      text-align: right;
      font-weight: bold;
      direction: rtl;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 15px;
    }
    .answer {
      margin-top: 10px;
      font-size: 16px;
      color: #4a4a4a;
      text-align: right;
      padding: 0;
      direction: rtl;
    }
    .back-home {
      direction: rtl;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 600px) {
  .question {
    font-size: 14px;
  }
  .answer {
    font-size: 14px;
  }
}
