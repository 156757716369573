.question-input-form {
  display: flex;
  justify-content: center;

  .input-container {
    position: relative;
    width: 560px;
    direction: rtl;
  }
  .mic-logo {
    width: 24px;
    left: 10px;
    top: 7px;
    position: absolute;
    z-index: 100;
  }

  .marginTop40 {
    margin-top: 40px !important;
  }
  .marginTop30 {
    margin-top: 30px !important;
  }

  .question-input {
    direction: rtl;
    border: 0;
    height: 100%;
    width: 100%;
    padding: 8px 10px 0 0;
    height: 42px;
    min-height: 42px;
    max-height: 75px;
    resize: none;
    position: relative;
    top: 6px;
    font-size: 18px;
    background-color: transparent;
    font-family: "openSans-regular";
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    background-image: url("../assets/input-text-logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .question-input::placeholder {
    color: #595959;
    font-size: 18px;
    font-family: "openSans-regular";
  }

  .question-input:focus {
    outline-width: 0;
  }

  .question-input:focus:not(.focus-visible) {
    outline: none;
  }

  .question-input-iphone {
    direction: rtl;
    border: 0;
    height: 100%;
    width: 85%;
    right: -20px;
    z-index: 10000;
    position: relative;
    top: 6px;
    font-size: 18px;
    background-color: transparent;
    font-family: "openSans-regular";
  }

  .question-input-iphone::placeholder {
    color: #595959;
    font-size: 18px;
    font-family: "openSans-regular";
  }

  .question-input-iphone:focus {
    outline-width: 0;
  }

  .question-input-iphone:focus:not(.focus-visible) {
    outline: none;
  }

  .question-input-img {
    position: absolute;
    width: unset;
    cursor: unset;
    right: 0;
    height: 46px;
    top: -3px;
    width: 560px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.box-minmax {
  margin-top: 30px;
  width: 225px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: black;
  span:first-child {
    margin-left: 5px;
  }
}
.rs-range {
  margin-top: 22px;
  width: 200px;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: black;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: black;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid black;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    height: 32px;
    width: 22px;
    border-radius: 22px;
    background: black;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -15px;
  }
  &::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid black;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.15);
    height: 32px;
    width: 22px;
    border-radius: 22px;
    background: black;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -15px;
  }
  &::-moz-focus-outer {
    border: 0;
  }
}
.rs-label {
  position: relative;
  transform-origin: center center;
  display: block;
  width: 22px;
  height: 22px;
  background: transparent;
  border-radius: 50%;
  // line-height: 30px;
  text-align: center;
  font-weight: bold;
  // padding-top: 22px;
  box-sizing: border-box;
  border: 1px solid black;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: black;
  &::after {
    display: block;
    font-size: 20px;
    letter-spacing: 0.07em;
    margin-top: -2px;
  }
}

@media only screen and (max-width: 600px) {
  // .rs-range {
  //   width: calc(100vw - 80px);
  // }

  // .box-minmax {
  //   width: calc(100vw - 77px);
  // }
  .question-input-form {
    .input-container {
      width: calc(100vw - 80px);
    }

    .question-input {
      font-size: 16px;
    }

    .question-input::placeholder {
      font-size: 16px;
    }

    .question-input-iphone {
      font-size: 16px;
    }

    .question-input-iphone {
      width: calc(100vw - 140px);
    }

    .question-input-iphone::placeholder {
      font-size: 16px;
    }

    .question-input-img {
      width: calc(100vw - 80px);
    }
  }
}
